import { graphql } from "gatsby";
import * as React from "react"

import Layout from "../components/layout"
import PageSection from "../components/page-section";
import Seo from "../components/seo"
import HeroImage from "../components/night-event/hero-image";
import MobileLogotypesSection from "../components/vistula-day/mobile-logotypes-section copy";
import { useDispatch } from "react-redux"
import { Dispatch } from "../store/store"
import { useEffect } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { DateTime } from "luxon"
import ClientOnly from "../components/client-only";
import ToursList from "../components/tour-calendar-list/list/ToursList";

const VistulaDayInfoPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch>();
  useEffect(() => {
    dispatch.toursCalendar.initialize({ translate: t, initDate: DateTime.fromISO("2022-09-23"), filterByProject: "NR_22", });
  }, [])

  return (
    <Layout>
      <Seo title={"Nadwiślańska Równonoc 2022"} />
      <HeroImage />
      <MobileLogotypesSection />
      <PageSection>
        <h1 className="pb-5">Świętujemy koniec lata 2022</h1>
        <div className="py-2" ></div>
        <p>23 września 2022 z okazji jesiennej równonocy zapraszamy na wspólne pożegnanie lata.</p>
        <p>O godzinie 17.30 spotykamy się na <a href="https://goo.gl/maps/JVtnMJMAac82NieT8" target="_blank">plaży Saska Kępa</a>, rozpalimy wielkie ognisko i zapleciemy tradycyjne wianki. Rozbrzmieją także tradycyjne pieśni z Polesia w wykonaniu zespołu Z LASU.</p>
        <p>Czeka na Was także inna muzyczna niespodzianka - wyjątkowy koncert LUNY o blasku księżyca.</p>
        <p>O godzinie 18.30 wyruszamy w rejs zakończony występem na wodzie w Porcie Czerniakowskim. Koncertowy rejs jest atrakcją biletowaną.</p>
        <a id="read-more-tickets" href="#toursCalendarSection" role="button" className="btn btn-outline-light btn mb-3 mb-sm-none">Rezerwuj rejs</a>
      </PageSection>
      <PageSection className='bg-white text-black position-relative' id="toursCalendarSection" data-bs-spy="scroll" data-bs-target="#read-more-tickets" style={{ minHeight: 400 }}>
        <h2><strong>Bilety</strong> na rejs</h2>
        <div className="py-3" ></div>
        <p>Zapisy na rejs zostaną uruchomione o godz. 20:00, 20 września 2022. </p>
        <ClientOnly>
          <ToursList />
        </ClientOnly>
      </PageSection>
    </Layout>
  );
}

export default VistulaDayInfoPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns:{in: ["navigation","footbar" ]},language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;