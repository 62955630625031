// i18next-extract-mark-ns-start main_page_hero
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { Trans, useI18next } from 'gatsby-plugin-react-i18next';

const HeroImage = () => {

    const { navigate } = useI18next();

    const goToTickets= () => navigate('/swieto-wisly/bilety');


    return (
        <div className="container-fluid p-0 overflow-hidden">
            <StaticImage
                    src='../../images/rownonoc-banner.png'
                    layout="fullWidth"
                    alt=""
                    formats={["auto", "webp", "avif"]}
                    placeholder="blurred"
                    transformOptions={{ fit: `contain` }}
                />
        </div >
    );
};

export default HeroImage;